import React from "react"
import { Link } from 'gatsby'
import trtData from '../content/trt-data.json'
import Layout from '../components/layout'
import GetInTouch from '../components/get-in-touch'
import Metatags from '../components/Metatags';

const PortfolioPage = () => {
    function PortfolioBox(title, image, imgAlt, detailsURL, description, i) {
        return (
            <div className="col-lg-4 col-sm-6" key={i}>
                <Link to={detailsURL}>
                    <div className="portfolio-box">
                        <div className="portfolio-img">
                            <img src={image} alt={imgAlt} />
                        </div>
                        <h5>{title}</h5>
                        <p>{description}</p>
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <Layout>
            <section className="trt-portfolio">
                <div className="container">
                    <div className="row portfolio-banner">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <h1 className="d-none d-sm-block">Portfolio</h1>
                        </div>
                        <div className="col-lg-5">
                            <h1 className="d-block d-sm-none text-center">Portfolio</h1>
                            <div className="text-right">
                                <img src="../images/portfolio/banner-icon.png"
                                    alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            trtData.portfolioBoxData.map(({ title, image, imgAlt, detailsURL, description }, i) => PortfolioBox(title, image, imgAlt, detailsURL, description, i))
                        }
                    </div>
                </div>
            </section>
            <GetInTouch className="d-block d-sm-none" />
        </Layout >
    )
}

export default PortfolioPage;